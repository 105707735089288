<template>
  <!-- Content -->
  <div class="bg-primary">
    <div class="container content-space-1 content-space-t-md-3">
      <div class="mx-auto" style="max-width: 30rem">
        <!-- Card -->
        <div class="card card-lg zi-2 shadow-sm border border-1">
          <!-- Header -->
          <div class="card-header text-center">
            <h4 class="card-title">Verify your email</h4>
            <p class="card-text">
              Thanks for signing up! before getting started could you verify
              your email address by clicking on the link we just emailed to you?
              if you didn't receive the email we will gladly send you another.
            </p>
          </div>
          <!-- End Header -->

          <!-- Card Body -->
          <div class="card-body">
            <template v-if="!in_submission">
              <div class="d-grid mb-4">
                <button
                  class="btn btn-primary rounded me-sm-2"
                  @click.prevent="resendEmailVerificationLink"
                  :disabled="in_submission"
                >
                  Resend verification email
                </button>
              </div>

              <div class="text-center">
                <a @click.prevent="logout" id="logout" :disabled="in_submission"
                  >Log Out</a
                >
              </div>
            </template>
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border text-primary"
                role="status"
                v-if="in_submission"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Card Body -->
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
  <!-- End Content -->

  <!-- Shape -->
  <div class="shape-container">
    <div class="shape shape-bottom zi-1">
      <svg
        viewBox="0 0 3000 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 600V350.234L3000 0V600H0Z" fill="#fff" />
      </svg>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "verifyEmail",
  data() {
    return {
      in_submission: false,
    };
  },
  methods: {
    async logout() {
      this.in_submission = true;
      await axiosConfig.post("/logout").then(async () => {
        await this.$store.dispatch("checkIfAuthenticated");
        this.$router.push({ name: "Home" });
      });
      this.in_submission = false;
    },
    async resendEmailVerificationLink() {
      this.in_submission = true;
      await axiosConfig
        .post("/email/verification-notification")
        .then(() => {
          this.in_submission = false;
        })
        .catch(async () => {
          this.in_submission = false;
          await this.$store.dispatch("checkIfAuthenticated");
          this.$router.push({ name: "Home" });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.verified == false && store.state.authenticated == true) {
      next();
    } else {
      next({ name: "Home" });
    }
  },
};
</script>

<style scoped>
#logout {
  text-decoration: underline;
  cursor: pointer;
}
</style>
